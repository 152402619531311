import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, Modal } from 'antd';
import { HomepageProps } from '@customTypes/dashboard';
import { ADD_NEW_PRODUCT, EDIT_NEW_PRODUCT } from '@constants/urls';

const { Title } = Typography;

const NewProductCard = ({ data }: { data: HomepageProps[] }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<Record<string, boolean>>({});

  const handleModal = (id: string) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const columns = [
    {
      title: 'Icon',
      key: 'url',
      dataIndex: 'url',
      width: 120,
      render: (text: string) => (
        <>
          <Button onClick={() => handleModal(text)}>Lihat Foto</Button>
          <Modal
            onCancel={() => handleModal(text)}
            visible={isModalOpen[text as keyof typeof isModalOpen]}
            footer={null}
          >
            <img src={text} alt="Icon" style={{ maxWidth: '100%' }} />
          </Modal>
        </>
      ),
    },
    {
      title: 'Judul Konten',
      key: 'title',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Deskripsi Konten',
      key: 'content',
      dataIndex: 'content',
      width: 400,
      ellipsis: true,
      render: (content: string) => (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ),
    },
    {
      title: 'Button Link',
      key: 'linkTo',
      dataIndex: ['otherData', 'linkTo'],
      width: 200,
      render: (linkTo: string) =>
        linkTo ? (
          <a href={linkTo} target="_blank" rel="noopener noreferrer">
            {linkTo}
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Aksi',
      key: 'action',
      width: 100,
      render: (_: string, record: HomepageProps) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_NEW_PRODUCT + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              New Product
            </Title>
            {data.length < 2 && (
              <Button onClick={() => navigate(ADD_NEW_PRODUCT)}>Tambah Baru</Button>
            )}
          </Space>
        }
      >
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
          />
        </div>
      </Card>
    </Space>
  );
};

export default NewProductCard;
